(function ($) {
  Drupal.behaviors.countdownTimerV1 = {
    attach: function (context) {
      var $templates = $('.js-countdown-timer--v1', context);

      $templates.each(function () {
        var $template = $(this);
        var $result = $('.js-countdown-result', $template);
        var $expired = $('.js-countdown-expired', $template);
        var $days = $('.js-countdown-days', $template);
        var $hours = $('.js-countdown-hours', $template);
        var $minutes = $('.js-countdown-minutes', $template);
        var $seconds = $('.js-countdown-seconds', $template);

        // Cosmetics
        if ($template.data('src')) {
          $template.css({ 'background-image': 'url(' + $template.data('src') + ')' });
        }

        // Set the date we're counting down to
        var futureDate = $template.data('countdown') ? $template.data('countdown').trim() : false;
        var countDownDate = new Date(futureDate).getTime();
        // Update the count down every 1 second
        var countdownTimerInterval = setInterval(function () {
          var firstTime = true;
          // Get today's date and time
          var now = new Date().getTime();
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          // Validate CMS input
          var isValid = true;

          _.each([days, hours, minutes, seconds], function (el) {
            if (isNaN(el)) {
              isValid = false;
            }
          });
          if (!isValid) {
            console.error('Error in countdown timer');
            clearInterval(countdownTimerInterval);

            return;
          }
          if (firstTime) {
            firstTime = false;
            $template.removeClass('hidden');
          }
          $days.html(days);
          if (!days) {
            $days.parent().hide();
          }
          $hours.html(hours);
          $minutes.html(minutes);
          $seconds.html(seconds);

          // If the count down is over, write some text
          if (distance < 0) {
            clearInterval(countdownTimerInterval);
            $result.addClass('hidden').attr('aria-hidden', true);
            $expired.removeClass('hidden').attr('aria-hidden', false);
          }
        }, 1000);
      });
    }
  };
})(jQuery);
